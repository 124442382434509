import { FC, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { bindActionCreators } from "@reduxjs/toolkit";
import { CommonAccountReportPropsExtended } from "../../types";
import { FeatureFlags } from "featureFlags";
import {
  useEventLineChartQuery,
  useEventEngagementQuery,
} from "features/Library/Events/EventDetails/Performance/Queries/queries";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import {
  dailyActivity,
  getBarDomain,
  lineChartTitles,
  noData,
  opensColor,
  QueryFilter,
  startsColor,
  totalActivity,
  validLineData,
} from "features/Library/Common/utils/performanceUtils";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { ColumnOption } from "interfaces/columnOptions";
import { TextTruncate } from "components";
import { Tooltip } from "components/common/tooltip";

import styles from "../../contentOverviewReports.module.scss";
import { ChartWrapper, getDateFormatByCount, HorizontalBarChart, LineChart } from "components/charts";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { barDataHasValues } from "features/Reporting/MsGraph/utils/utils";
import { sharedAccountReportingHorizontalBarProps, sharedAccountReportingLineProps } from "../../shared";
import CardReporting from "components/cards/CardReporting/CardReporting";
import { GenericPerformanceList } from "../../shared/GenericPerformanceList";
import { AppDispatch } from "features/Application/globaltypes/redux";
import { connect, ConnectedProps } from "react-redux";
import { bindAction } from "interfaces";
import { reset, setIsReportEnabled } from "features/Reporting/state/toolbar/toolbarSlice";
import { setExportAction, setHasDataToExport } from "features/Reporting/state/export/exportSlice";
import { doEventExport } from "features/Reporting/state/content/contentActions";
import { ReportUnavailable } from "components/reportUnavailable/ReportUnavailable";
import { AxisDomain } from "d3";
import moboConfigurator from "../../../../../moboConfigurator";

export type Props = CommonAccountReportPropsExtended & PropsFromRedux;

export interface EventsTableEntity {
  EventId: number;
  EventTitle: string;
  Registered: number;
  Attended: number;
  NotAttended: number;
  LastActivity: string; // timestamp (ie "2024-02-05 15:28:13")
}

const fetchTableQuery = async (filter: QueryFilter) => {
  const { data } = await axios.get<EventsTableEntity[]>(`/api/reports/v2/overview/events/performance`, {
    params: filter.queryKey[1],
  });
  return data.map((item) => ({
    ...item,
    id: item.EventId,
  }));
};

const colorScale = [startsColor, opensColor];

const legendLabelFormat = (label: AxisDomain): string => {
  if (label === "Registered") return "Users who registered for an event.";
  if (label === "Attended") return "Users who attended an event.";
  return "Users who registered for an event, but did not attend.";
};

export const EventsReport: FC<Props> = ({
  filter,
  setIsReportEnabled,
  resetIsReportEnabled,
  eventExport,
  setExportAction,
  setHasExportData,
}) => {
  const reportEnabled = useFeatureFlag(FeatureFlags.EventPerformanceOverview);
  const [chartState, setChartState] = useState(totalActivity);
  const lineChartQuery = useEventLineChartQuery({ filter, eventId: -1, chartPeriod: "DAY", reportEnabled });
  const engagementQuery = useEventEngagementQuery({ filter, eventId: -1, reportEnabled, isOverview: true });
  const tableQuery = useQuery({
    queryKey: ["overviewEventsTable", filter],
    queryFn: fetchTableQuery,
    enabled: reportEnabled,
  });

  useEffect(() => {
    setHasExportData(!!tableQuery.data && tableQuery.data.length > 0);
  }, [tableQuery.data, setHasExportData]);

  useEffect(() => {
    setIsReportEnabled(reportEnabled);

    return () => {
      resetIsReportEnabled();
    };
  }, [reportEnabled, resetIsReportEnabled, setIsReportEnabled]);

  useEffect(() => {
    setExportAction({
      method: eventExport,
      args: [filter],
      isExportEnabled: true,
    });
  }, [eventExport, filter, setExportAction]);

  const lineData = useMemo(() => {
    if (chartState === dailyActivity) {
      return {
        xData: [lineChartQuery.data?.lineData?.Date, lineChartQuery.data?.lineData?.Date],
        yData: [lineChartQuery.data?.lineData?.Registered, lineChartQuery.data?.lineData?.Attended],
      };
    }
    return {
      xData: [lineChartQuery.data?.lineData?.Date, lineChartQuery.data?.lineData?.Date],
      yData: [lineChartQuery.data?.lineData?.CumulativeRegistered, lineChartQuery.data?.lineData?.CumulativeAttended],
    };
  }, [chartState, lineChartQuery.data]);

  const columns: ColumnOption<EventsTableEntity>[] = useMemo(
    () => [
      {
        name: "EVENT",
        width: 7,
        isSortable: false,
        render(item) {
          return (
            <Tooltip
              target={
                <TextTruncate lines={2} className={styles.wordBreak}>
                  {filter.showCustomers ? (
                    item.EventTitle
                  ) : (
                    <Link to={moboConfigurator.appendMoboIdToUrl(`/content/events/${item.EventId}`)}>{item.EventTitle || item.EventId.toString()}</Link>
                  )}
                </TextTruncate>
              }
              content={item.EventTitle}
            />
          );
        },
      },
      {
        name: "TOTAL REGISTERED",
        width: 4,
        isSortable: false,
        render(item) {
          return (item.Registered ?? 0).toLocaleString();
        },
      },
      {
        name: "TOTAL ATTENDED",
        width: 4,
        isSortable: false,
        render(item) {
          return (item.Attended ?? 0).toLocaleString();
        },
      },
      {
        name: "ATTENDANCE RATE",
        width: 4,
        isSortable: false,
        render(item) {
          const attendanceRate = Math.round((item.Attended / item.Registered) * 100);
          return isFinite(attendanceRate) ? `${attendanceRate}%` : "100%"; // Handles when registered is 0 and attended is non-zero, which can occur if an event was registered for, out of the date range, and then attended within the date range.
        },
      },
    ],
    [filter.showCustomers],
  );

  if (!reportEnabled) {
    return <ReportUnavailable />;
  }

  return (
    <div className={styles.reportBody}>
      <div className={styles.lineChartContainer}>
        <ChartWrapper
          titles={lineChartTitles}
          showLegend
          legendLabels={["Registered", "Attended"]}
          colorRange={colorScale}
          onChange={(v) => setChartState(v)}
          legendTooltip={legendLabelFormat}
        >
          <RequestStatusRenderer state={lineChartQuery.status}>
            {lineChartQuery.isSuccess &&
            validLineData([
              lineChartQuery.data.lineData.Attended,
              lineChartQuery.data.lineData.CumulativeAttended,
              lineChartQuery.data.lineData.Registered,
              lineChartQuery.data.lineData.CumulativeRegistered,
            ]) ? (
              <LineChart
                {...sharedAccountReportingLineProps}
                {...lineData}
                colorRange={colorScale}
                xFormatterFunc={getDateFormatByCount(lineChartQuery.data.lineData.Date.length)}
              />
            ) : (
              noData(filter)
            )}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>
      <div className={styles.funnelChartContainer}>
        <ChartWrapper titles={["Total Engagement"]}>
          <RequestStatusRenderer state={engagementQuery.status}>
            {engagementQuery.isSuccess && barDataHasValues(engagementQuery.data.engagement) ? (
              <HorizontalBarChart
                {...sharedAccountReportingHorizontalBarProps}
                data={engagementQuery.data.engagement}
                domain={getBarDomain(engagementQuery.data.engagement)}
                yAxisTickTooltipFormat={legendLabelFormat}
              />
            ) : (
              noData(filter)
            )}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>
      <div className={styles.performanceCardSection}>
        <RequestStatusRenderer state={engagementQuery.status}>
          {engagementQuery.isSuccess && <CardReporting items={engagementQuery.data.cards} />}
        </RequestStatusRenderer>
      </div>
      <div className={styles.table}>
        <RequestStatusRenderer state={tableQuery.status}>
          {tableQuery.isSuccess && <GenericPerformanceList title="Events" rows={tableQuery.data} columns={columns} filter={filter} />}
        </RequestStatusRenderer>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setHasExportData: bindAction(setHasDataToExport, dispatch),
  eventExport: bindActionCreators(doEventExport, dispatch),
  setExportAction: bindAction(setExportAction, dispatch),
  setIsReportEnabled: bindAction(setIsReportEnabled, dispatch),
  resetIsReportEnabled: bindAction(reset, dispatch),
});

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EventsReport);
